<template>
  <div class="post-component">
    <div class="post-date">{{date}}</div>
    <slot name="text"></slot>
    <slot name="photo-collection"></slot>
    <div v-if="links">
      <p>Ксении Карцевой - <a :href="links[0]">{{links[0]}}</a></p>
      <p>Егора Томашова - <a :href="links[1]">{{links[1]}}</a></p>
      <p>Дарины Макаровой - <a :href="links[2]">{{links[2]}}</a></p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(['date', 'links'])
console.log(props)
</script>

<style lang="scss" scoped>
  .post-component {
    margin-top: 50px;
    padding: 20px 80px 20px 50px;
    width: 75%;
    font-size: 16px;
    border: 2px solid lightgray;
    border-radius: 4px;
    background-color: #edf1ff;
  }

  .post-date {
    margin-bottom: 40px;
    color: gray;
  }
</style>