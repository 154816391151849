<template>
  <div class="for-parents">
    <div class="container">
      <h2>Педагогам и родителям</h2>
      <p v-for="(item, i) in files" v-bind:key="i">
        <a class="for-parents__link"
           :href="item.link"
           target="_blank"
        >
          {{item.name}}
        </a>
      </p>
      <h3>Полезные ссылки:</h3>
      <p v-for="(item, i) in usefulLinks" v-bind:key="i">
        <a class="for-parents__link"
           :href="item.link"
           target="_blank"
        >
          {{item.name}}
        </a>
      </p>
    </div>
  </div>
</template>
<script setup>
const files = [
  {
    name: 'Консультация для родителей: "Наши друзья - животные"',
    link: 'https://drive.google.com/file/d/186ZpKXY_5vpnqxoprsAHgAfPFkV6K77m/view?usp=drive_link',
  },
  {
    name: 'Советы родителям по использованию детьми компьютера и сети Интернет',
    link: 'https://drive.google.com/file/d/1guuJyiqbnJUm2nKwLFrsTcPnF8EmeUm6/view?usp=drive_link',
  },
  {
    name: 'Консультация для родителей: "Графический диктант и его значение"',
    link: 'https://drive.google.com/file/d/16EUo4XjkRAOGFG1zXFRtRj0GWkGr5aU9/view?usp=drive_link'
  },
  {
    name: 'Консультация: "Сухой бассейн - эффективное средство оздоровления дошкольников"',
    link: 'https://drive.google.com/file/d/1FTW9xkCQb1SGtQS79IPf4DfoyOJTmZck/view?usp=drive_link'
  },
  {
    name: 'Консультация для родителей: "30 игр от Марии Монтессори"',
    link: 'https://drive.google.com/file/d/1Qczghuz0vQJqFpFA0MVYWZMU6YAY4vfF/view?usp=drive_link'
  },
  {
    name: 'Консультация: "Учимся завязывать шнурки"',
    link: 'https://drive.google.com/file/d/1o9xeWaKcA1qZJRcV_Q8mCZxT0oL0uYy2/view?usp=drive_link'
  },
  {
    name: 'Консультация: "Ребёнок храпит - стоит ли быть тревогу"',
    link: 'https://drive.google.com/file/d/1bnzv4FrFSrSRsIkO8ozhKwQekKZa4M3-/view?usp=drive_link'
  },
  {
    name: 'Консультация для родителей: "6 родительских заблуждений о морозной погоде"',
    link: 'https://drive.google.com/file/d/14f1l-5GWdePZeiEiZ_mRVHt9mKx6MYWp/view?usp=drive_link'
  },
  {
    name: 'Как и зачем родителям играть с детьми',
    link: 'https://drive.google.com/file/d/1jm_-3H5vGEgu3HR9YUnyYex_e9pxcOrP/view?usp=drive_link'
  },
  {
    name: 'Как снять напряжение после детского сада',
    link: 'https://drive.google.com/file/d/1fTT1mh81cNYfhK_y4Mc40UycE-QHpQ3s/view?usp=drive_link'
  },
  {
    name: 'Памятки для родителей',
    link: 'https://drive.google.com/file/d/1CexEkxcnDV9f_nTz2MWAutL66EhRrRNH/view?usp=drive_link'
  },
  {
    name: 'Координация речи с движением. Сборник стихов "Семья"',
    link: 'https://drive.google.com/file/d/1JkZDHxQA2Q95n_eTpXqTqKk3N4wXgvgg/view?usp=drive_link'
  },
  {
    name: 'Буклет для родителей ФОП ДО',
    link: 'https://drive.google.com/file/d/18xYwFdbIWInMDCpN86wRDkRcSvuNTP99/view?usp=drive_link',
  },
  {
    name: 'Консультация для родителей "Скоро выпускной"',
    link: 'https://drive.google.com/file/d/1pPz-RF9o_S3g8Gmp5nuPd6v-uY-GWaIN/view?usp=drive_link',
  },
  {
    name: 'Важные правила безопасности для детей',
    link: 'https://drive.google.com/file/d/1cLrygailO1jRgirDRWkIICQzD7D47wHV/view?usp=drive_link',
  },
  {
    name: 'Как вести себя в театре',
    link: 'https://drive.google.com/file/d/10pK6oeuT0V-awF1iS9mwoYopE5uTOpvg/view?usp=drive_link',
  },
  {
    name: 'Пальчиковая гимнастика',
    link: 'https://drive.google.com/file/d/1heYzvdI_AvBLRP8QYYvGdph0d8YiKej3/view?usp=drive_link',
  },
  {
    name: 'https://drive.google.com/file/d/1heYzvdI_AvBLRP8QYYvGdph0d8YiKej3/view?usp=drive_link',
    link: 'https://drive.google.com/file/d/1cKMTShrFNBk6GSQmJ5xtjTi6wbMHeF9W/view?usp=drive_link',
  },
  {
    name: 'Расскажите детям о космонавтике',
    link: 'https://drive.google.com/file/d/18qEORCAr_wCmnLk5rjhYEwvOdETlwFAa/view?usp=drive_link',
  },
  {
    name: 'Разрезные картинки "Весна"',
    link: 'https://drive.google.com/file/d/1CYy_KIsKnVA5euf8NNI-IYV74H7Tx_mj/view?usp=drive_link',
  },
  {
    name: 'Едим сладости правильно',
    link: 'https://drive.google.com/file/d/1Z3uWhCLWfgOB5s6LNdQMAAtEjklMDyDq/view?usp=drive_link',
  },
  {
    name: 'Консультация для родителей "Научите детей общаться"',
    link: 'https://drive.google.com/file/d/1BzjJhHw2tUNpskEZPXdzUtneuBhegAfd/view?usp=drive_link',
  },
  {
    name: 'Памятки родителям "Правила поведения в толпе"',
    link: 'https://drive.google.com/file/d/1ADsdeF06g77Dibr0NMszsFawPZqqQL45/view?usp=drive_link'
  },
  {
    name: 'Консультация о правилах дорожного движения',
    link: 'https://docs.google.com/document/d/12kVEkM7QvJpYizVOIxQfWiS6l8btYzn-/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'О руководстве игрой ребёнка в семье',
    link: 'https://docs.google.com/document/d/1-flhPF3Xasm93wGp4asrADiL5tv-fo9P/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Как говорить с детьми о блокаде',
    link: 'https://drive.google.com/file/d/1VdelqyHa146jaPdQkxoBbX9gr5mfJCVV/view?usp=drive_link'
  },
  {
    name: 'Консультация для родителей "Как превратить чтение в удовольствие"',
    link: 'https://drive.google.com/file/d/1wKWNDtBwMUy7bqIHVpoNXfu5HIaynpMR/view?usp=drive_link',
  },
  {
    name: 'Экологические сказки и рассказы',
    link: 'https://drive.google.com/file/d/1e08CRCkr7qsVSCCnrENrxmSPIbjOP5V3/view?usp=drive_link'
  }
];

const usefulLinks = [
  {
    name: 'Развитие и воспитание детей',
    link: 'https://vk.com/razvitie_detei',
  },
  {
    name: 'Для мамочек и воспитателей (дети и ДОУ)',
    link: 'https://vk.com/deti_dou',
  },
  {
    name: 'Я - воспитатель',
    link: 'https://vk.com/yavosp',
  },
  {
    name: 'Учимся играя!',
    link: 'https://vk.com/deti_uchatsya',
  },
  {
    name: 'Шаблоны для печати (file-shop.ru)',
    link: 'https://vk.com/file_shop_ru',
  },
  {
    name: 'ПАЛОЧКА-ВЫРУЧАЛОЧКА педагогам, воспитателям',
    link: 'https://vk.com/club175840060',
  },
  {
    name: 'папка - передвижки для родителей и воспитателей',
    link: 'https://vk.com/papki_peredvizhki',
  },
  {
    name: 'Дошколёнок: Обучение и воспитание дошкольников',
    link: 'https://vk.com/detskiysadmoscow',
  },
  {
    name: '"Поделочка" Поделки для детей',
    link: 'https://vk.com/detskie_podelky',
  },
  {
    name: 'Творчество с детьми (шаблоны)',
    link: 'https://vk.com/cherrymom_off',
  },
  {
    name: 'ДВОРЕЦ ПОЛЕЗНЫХ МАТЕРИАЛОВ',
    link: 'https://vk.com/club194581003',
  },
  {
    name: 'Всероссийский портал "Педсовет"',
    link: 'https://vk.com/vkpedsov',
  },
  {
    name: 'Остров знаний | Развитие дошкольников',
    link: 'https://vk.com/ostrovznani',
  },
  {
    name: 'Календарно-тематические планы ДОУ по ФОП и ФГОС',
    link: 'https://vk.com/detsadplan',
  },
  {
    name: 'фотозона',
    link: 'https://vk.com/fotozona_oformlenie',
  },
  {
    name: 'РАСПЕЧАТАЙ И ИГРАЙ | ИГРЫ НА ЛИПУЧКАХ',
    link: 'https://vk.com/raspe4atai_igrai',
  },
  {
    name: '"СУНДУЧОК" для воспитателей и родителей',
    link: 'https://vk.com/club_sunduk_ru',
  },
  {
    name: 'Надежный взрослый',
    link: 'https://vk.com/eq_coach',
  },
  {
    name: 'От рождения до школы',
    link: 'https://vk.com/club186517750',
  },
  {
    name: 'ВОСПИТАТЕЛЯМ В ПОМОЩЬ',
    link: 'https://vk.com/detsad_lifest',
  },
  {
    name: '"СОЗИДАТЕЛЬНЫЙ ПЕДАГОГ"',
    link: 'https://vk.com/club162772349',
  },
  {
    name: '\n' +
        'Педагогические Полезности',
    link: 'https://vk.com/club211766504',
  },

];
</script>

<style lang="scss" scoped>
.for-parents {
  margin-bottom: 50px;
  h3 {
    margin-top: 60px;
  }
}

.for-parents__link {
  color: #0f75bd;
  text-decoration: underline;
  &:active {
    color: #22409a;
  }
}
</style>