<template>
  <div class="photo-collection">
    <slot name="header"></slot>
    <div class="photo-container" :class="{'_in-post': inPost}">
      <div
          v-for="(item, i) in photos"
          v-bind:key="i"
          class="photo-collection__img-wrapper"
          :class="{'_in-post': inPost, '_in-about': inAbout}"
      >
        <img
            class="photo-collection__img"
            :src="item"
            @click="openImg(i + 1)"
        >
      </div>
    </div>
    <photo-modal
        :modal-is-open="modalIsOpen"
        :img-src="require(`@/assets/${directory}/img${imgNumber}.jpeg`)"
        :disabled="isButtonDisabled"
        @toggle-modal="modalIsOpen = false"
        @swap-img="swapImg"
    ></photo-modal>
  </div>
</template>

<script setup>
import {computed, ref} from "vue";
import PhotoModal from "@/components/MainContent/Photo/PhotoModal.vue";

const props = defineProps(['directory', 'amount', 'inPost', 'inAbout'])
const imgNumber = ref('1')
const modalIsOpen = ref(false);
const photos = getPhotos();

const isButtonDisabled = computed(() => {
  if (imgNumber.value == 1) return 'left'
  else if (imgNumber.value == photos.length) return 'right'
  else return '';
})

function getPhotos() {
  const photos = [];
  for (let i = 1; i <= props.amount; i++) {
    const photoSrc = require('@/assets/' + props.directory + '/img' + i + '.jpeg');
    photos.push(photoSrc);
  }
  return photos;
}

function openImg(src) {
  imgNumber.value = src;
  modalIsOpen.value = true;
}

function swapImg(direction) {
  if (direction === 'left') {
    imgNumber.value -= 1;
  } else if (direction === 'right') {
    imgNumber.value += 1;
  }
}
</script>

<style lang="scss">
.photo-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 30px;


}
.photo-collection__img-wrapper {
  height: 180px;
  width: 162px;
  margin-right: 3px;
  margin-bottom: 30px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 5px;
  &._in-post {
    height: 162px;
    width: 162px;
    border-radius: 0;
    border: 1px solid white;
    margin-right: 0;
    margin-bottom: 0;
  }
  &._in-about {
    height: 200px;
    width: 220px;
  }
}

.photo-collection__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1240px) {
  .photo-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1124px) {
  .photo-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 920px) {
  .photo-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 662px) {
  .photo-container {
    grid-template-columns: 1fr 1fr;
    &._in-post {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .photo-collection__img-wrapper._in-post {
    width: 124px;
    height: 124px;
  }
}

@media screen and (max-width: 480px) {
  .photo-container {
    grid-template-columns: 1fr;
    &._in-post {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>