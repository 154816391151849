<template>
  <nav class="navigation-panel">
    <navigation-item v-for="(item, i) in list" v-bind:key="i" :link="item"/>
  </nav>
</template>
<script>
import {reactive} from "vue";
import NavigationItem from "@/components/Navigation/NavigationItem.vue";

export default {
  components: {NavigationItem},
  setup() {
    const list = reactive(['about', 'news', 'for_teachers_and_parents', 'for_kids', 'methodical_materials', 'gallery', 'feedback']);
    return {
      list
    }
  }
}
</script>

<style scoped>
.navigation-panel {
  width: 100vw;
  height: 42px;
  display: flex;
}


@media screen and (max-width: 1024px) {
  .navigation-panel {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .navigation-panel {
    display: none;
  }
}
</style>