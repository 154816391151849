<template>
  <div class="for-kids">
    <div class="container">
      <h2>Детям</h2>
      <p v-for="(item, i) in files" v-bind:key="i">
        <a class="for-kids__link"
           :href="item.link"
           target="_blank"
        >
          {{item.name}}
        </a>
      </p>
    </div>
  </div>
</template>
<script setup>
const files = [
  {
    name: 'Россия - Мульти-Россия I Мультфильм',
    link: 'https://www.youtube.com/watch?v=HaNYaBJoDMw',
  },
  {
    name: '"Богатый Бобрёнок" — интерактивный развлекательно-просветительский мульт-сериал',
    link: 'https://www.youtube.com/channel/UC82QCV1QsK5MTGGjm80osQw',
  },
  {
    name: 'Фиксики - Сборник - Все серии',
    link: 'https://www.youtube.com/watch?v=YaYxSzeZabU&ab_channel=%D0%A4%D0%B8%D0%BA%D1%81%D0%B8%D0%BA%D0%B8%E2%80%94%D0%BD%D0%BE%D0%B2%D1%8B%D0%B5%D1%81%D0%B5%D1%80%D0%B8%D0%B8%D0%B8%D1%81%D0%B1%D0%BE%D1%80%D0%BD%D0%B8%D0%BA%D0%B8%D0%BC%D1%83%D0%BB%D1%8C%D1%82%D1%84%D0%B8%D0%BB%D1%8C%D0%BC%D0%B0',
  },
  {
    name: 'Мультсериал "Уроки тётушки Совы"',
    link: 'https://mult.tv/uroki-tetushki-sovy',
  },
  {
    name: 'ВСЕ СЕРИИ ПОДРЯД! - СБОРНИК ЛЮБИМЫХ СЕРИЙ О ЖИВОТНЫХ - Профессор Почемушкин',
    link: 'https://youtu.be/URkiP7S51YU?feature=shared',
  },
  {
    name: 'ПРОФЕССОР ПОЧЕМУШКИН - СБОРНИК ПОЗНАВАТЕЛЬНЫХ МУЛЬТФИЛЬМОВ ДЛЯ ДЕТЕЙ - Все серии подряд :)',
    link: 'https://youtu.be/UOLwsQT2Ta8?feature=shared',
  },
  {
    name: 'ПРОФЕССОР ПОЧЕМУШКИН - СБОРНИК ПОЗНАВАТЕЛЬНЫХ МУЛЬТФИЛЬМОВ ДЛЯ ДЕТЕЙ',
    link: 'https://youtu.be/3TKTVtFTDVw?feature=shared',
  },
  {
    name: 'ПРЕМЬЕРА!!! ШКОЛА БЕЗОПАСНОСТИ ПРОФЕССОРА ПОЧЕМУШКИНА - Что делать, если ты потерялся в городе?',
    link: 'https://youtu.be/OdaVZGPmcDA?feature=shared',
  },
  {
    name: 'НОВАЯ СЕРИЯ! Почему День космонавтики отмечают 12 апреля? - Профессор Почемушкин новые серии',
    link: 'https://youtu.be/9QhgYZpokNE?feature=shared',
  },

  {
    name: 'Азбука финансовой грамотности. Все серии - Смешарики 2D.',
    link: 'https://www.youtube.com/watch?v=KKF3pXWUhro&ab_channel=TVSmeshariki',
  },
  {
    name: 'Лото-загадки "Дикие животные"',
    link: 'https://drive.google.com/file/d/1ayjXqqyoXy-qK9gSZfkCxqe3TESuXvRJ/view?usp=drive_link',
  },
  {
    name: 'Прописи',
    link: 'https://drive.google.com/file/d/1WLhkMy0B74KQBL-jS0sT22jm3q7yEevl/view?usp=drive_link',
  },
  {
    name: 'Раскраска',
    link: 'https://drive.google.com/file/d/1NhwTND5mGeuqQWM6z6ZbB-Fm5Kui7VOJ/view?usp=drive_link',
  },
  {
    name: 'Сердечки для мам к 8 марта',
    link: 'https://drive.google.com/file/d/12snMYIJtiksQmBQLqxfpaXYl2CM5OkkP/view?usp=drive_link'
  },
  {
    name: '8 марта - праздник мам',
    link: 'https://drive.google.com/file/d/195Uv5WHCsluFzuyCLxZ2xk_FixZDFFQm/view?usp=drive_link'
  },
  {
    name: 'Загадки',
    link: 'https://drive.google.com/file/d/12qE9IRlkKNHuXAVwJfwOnXyCQMUMtgBt/view?usp=drive_link'
  },
  {
    name: 'Раскраски к 8 Марта',
    link: 'https://drive.google.com/file/d/1cXlAp0eXfVrhsltDMi5XH2zgxg1mF8dY/view?usp=drive_link'
  },
  {
    name: 'Ребусы для детей',
    link: 'https://drive.google.com/file/d/1g0dd-oswM-r1MzhZ6BOuA6hLNOCUEPPv/view?usp=drive_link',
  },
  {
    name: 'Пасхальные раскраски',
    link: 'https://drive.google.com/file/d/128kGb2aKYmdNpCVFt1n_yoGqil9zXV9e/view?usp=drive_link',
  },
  {
    name: 'Игра "Угадай сказку К. И. Чуковского"',
    link: 'https://drive.google.com/file/d/1RNe5gqBXTkCDsfoGoYFZ8gkbnfew5FOJ/view?usp=drive_link',
  },
  {
    name: 'Найди предметы',
    link: 'https://drive.google.com/file/d/1Y9hJjc_rfYgOTdnNOskUIT6v3o3C9lHx/view?usp=drive_link',
  },
  {
    name: 'НАХОДИЛКИ. РАЗВИВАЕМ ВНИМАНИЕ.',
    link: 'https://drive.google.com/file/d/1SrVLAVVeMr5I_1P9lHoFGY7xcP3dWWwk/view?usp=drive_link',
  },
  {
    name: 'Раскраски масленница',
    link: 'https://drive.google.com/file/d/1zT-zsnrtE4Jp1KXK1-23-gNOn-4Ll5tf/view?usp=drive_link',
  },
  {
    name: 'Раскраски "Микробы и бактерии"',
    link: 'https://drive.google.com/file/d/1-x8rilnO3hGHXyUTTndU9ORc53YNEfpF/view?usp=drive_link',
  },
]
</script>

<style lang="scss" scoped>
.for-kids {
  margin-bottom: 50px;
}

.for-kids__link {
  color: #0f75bd;
  text-decoration: underline;
  &:active {
    color: #22409a;
  }
}
</style>