<template>
  <div class="methodical-materials">
    <div class="container">
      <h2>Методическая копилка</h2>
      <p v-for="(item, i) in files" v-bind:key="i">
        <a class="methodical-materials__link"
          :href="item.link"
          target="_blank"
        >
          {{item.name}}
        </a>
      </p>
    </div>
  </div>
</template>

<script setup>
const files = [
  {
    name: 'Дипломы "Мама, папа, я - спортивная семья"',
    link: 'https://drive.google.com/file/d/1hJvPict4DoopcSICJK4fd2TI09_SKVWW/view?usp=drive_link',
  },
  {
    name: 'С днём защитника отечества',
    link: 'https://drive.google.com/file/d/1a3-kIweNWrh10G8Y6RRVVIoy-435ikxx/view?usp=drive_link'
  },
  {
    name: 'Эмблемы на 23-е февраля',
    link: 'https://drive.google.com/file/d/1bFomcIwizgfQSi5PK_iKNMzz2VZXnFMg/view?usp=drive_link'
  },
  {
    name: 'Части суток',
    link: 'https://drive.google.com/file/d/1WMhR4HRrZ-gO4BNiiYOopdHclJKM4cnA/view?usp=drive_link'
  },
  {
    name: 'День победы',
    link: 'https://drive.google.com/file/d/1PuG1ampYJiObtjGm7HdNiGAv1B_YfKaj/view?usp=drive_link'
  },
  {
    name: 'Времена года',
    link: 'https://drive.google.com/file/d/1GA8iwUgSVzPv-ymxCddIPxgdkCdM_sxZ/view?usp=drive_link'
  },
  {
    name: 'Комплексные занятия в средней группе детского сада',
    link: 'https://drive.google.com/file/d/1r0tDO-kU8JJ7RqaxkZuiAwYDzAlPtP2_/view?usp=drive_link'
  },
  {
    name: 'Беседа-игра "Всемирный день авиации и космонавтики"',
    link: 'https://docs.google.com/document/d/1DegRLTWpjxQrj8pV-sUjx4Z9CdoGw3S5/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: '«ДЕНЬ КОСМОНАВТИКИ»',
    link: 'https://docs.google.com/document/d/1YKmIgFD8Gdic277iD9QNYvBffmedU-_q/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Ирина Александровна Помораева, Вера Арнольдовна Позина\n' +
        'Формирование элементарных математических представлений. \n' +
        'Система работы в подготовительной к школе группе детского сада',
    link: 'https://docs.google.com/document/d/1pyBqUFBz1LMx6AXEVU_KDsg6ApUXzvs-/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'ФГОС дошкольного образования',
    link: 'https://drive.google.com/file/d/1AY6mMK4BuR-CB7abC7IdjE4K6nm_IN4k/view?usp=drive_link'
  },
  {
    name: 'Методическое пособие педагогических мероприятий \n' +
        'по финансовой грамотности для детей старшего \n' +
        'дошкольного возраста',
    link: 'https://docs.google.com/document/d/19czTv1-6jVGn_NL5GAK-K5eoLsyFJ-QV/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Составление описательного рассказа о весне по сюжетным картинкам и опорному картинному \n' +
        'плану.',
    link: 'https://docs.google.com/document/d/1qxBaypwAM_GN5R9mDRUWeAUlLzfWkx7q/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'КАРТОТЕКА «Дидактические игры по формированию финансовой \n' +
        'грамотности у старших дошкольников»',
    link: 'https://docs.google.com/document/d/1oie8glYdy-uVK1G4T1sGw-eUccD216ng/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'КАРТОТЕКА ИГР ПО \n' +
        'МАТЕМАТИКЕ\n' +
        '      С ИСПОЛЬЗОВАНИЕМ ЛЕГО \n' +
        'КОНСТРУКТОРА',
    link: 'https://docs.google.com/document/d/1sHkoArXjUzkT3qShVffloxn-EgeuNg3A/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека игр \n' +
        'с кинетическим песком',
    link: 'https://docs.google.com/document/d/1vS0Ia6iFgBC-o7LeydVpKmFPu44kHPJ3/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека позитивных игр',
    link: 'https://docs.google.com/document/d/18P66Sh0xiBkhF1LT040OAyIozSPeytd2/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Игра',
    link: 'https://docs.google.com/document/d/18P66Sh0xiBkhF1LT040OAyIozSPeytd2/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Карты под игры',
    link: 'https://docs.google.com/document/d/1UcdrsN2KN_VCAUO30wGPKh-m_1cSYFwj/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Игры на развитие двигательных способностей.',
    link: 'https://docs.google.com/document/d/1iujK1icFUOtNWmS9QpdxL3QSkDb_Osv9/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека пальчиковых игр, упражнений',
    link: 'https://docs.google.com/document/d/1mTvChToxj6FPqPtJ5XfkrsEgXp6Qk74u/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека пальчиковых игр "Радуга"',
    link: 'https://docs.google.com/document/d/1qTzqJJVMZPqJBe5x-2vgukE5R6Daj4uw/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Пальчиковая гимнастика',
    link: 'https://docs.google.com/document/d/1OOlwbRAen8szaNBJcvYPneybGPmX4BBH/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Развивающие упражнения для самых \n' +
        '                                          маленьких.(2-4 года).',
    link: 'https://docs.google.com/document/d/1d4M5sz_KHMH--agWRzTefrLdBat-4bpB/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека опытов',
    link: 'https://docs.google.com/document/d/1Bc-ZsY0gJ5eN72pPih2kWcdZ5cUqRkSK/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека утреннего сбора в подготовительной группе',
    link: 'https://drive.google.com/file/d/1DrRIceanwB58QWt-WyH-uBdG4O8SN1Nx/view?usp=drive_link'
  },
  {
    name: 'Материал к занятиям по развитию речи',
    link: 'https://drive.google.com/file/d/12P6P7gm-wVBVOdLAzunSNrRE1kDmT_Tb/view?usp=drive_link'
  },
  {
    name: 'Рабочая тетрадь № 2 для подготовительной к школе логопедической \n' +
        'группы ',
    link: 'https://docs.google.com/document/d/1qOepf06c14L4mjIBkEG6xPoqn8IZzOaw/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Мастер-класс для педагогов ДОО\n' +
        '«Использование технологии «Утренний» и «Вечерний» круг на практике»\n',
    link: 'https://docs.google.com/document/d/1hOa491qjkLdALIIuOk52Aaqy8N50kMes/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'КОНСУЛЬТАЦИЯ НА ТЕМУ: «ПАЛЬЧИКОВАЯ \n' +
        'ГИМНАСТИКА ДЛЯ РАЗВИТИЯ РУЧНОЙ УМЕЛОСТИ»',
    link: 'https://docs.google.com/document/d/1MdCqAA2iXO3Zf39U9z5ubzNfRJ6WZEF1/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Рабочая тетрадь по основам финансовой грамотности для\n' +
        'детей дошкольного возраста',
    link: 'https://drive.google.com/file/d/1r4GK9CDEANmA9rtXjlSQ2aeGmKBh6okc/view?usp=drive_link'
  },
  {
    name: 'Развитие мелкой моторики, пальчиковая гимнастика для младшей и средней группы',
    link: 'https://drive.google.com/file/d/1yX9jNLR6NAJ-azVQOevl8cd0hl7hbsng/view?usp=drive_link'
  },
  {
    name: 'Теория и практика развития речи дошкольника',
    link: 'https://drive.google.com/file/d/180h1OGoKx0oxws5ytQFicyfnV9GUr4st/view?usp=drive_link'
  },
  {
    name: 'Игры для утреннего сбора',
    link: 'https://drive.google.com/file/d/1pWkW1KCJBwJJSmQgCEGnYNZCOEg4ekiw/view?usp=drive_link'
  },
  {
    name: 'Изобразительная деятельность в детском саду, средняя группа',
    link: 'https://drive.google.com/file/d/1vzn9V7NOLD639DisC89193wZ8jFHW1Dy/view?usp=drive_link'
  },
  {
    name: 'Пластилинография',
    link: 'https://docs.google.com/document/d/1UJfuI6FZJNNZyIppDCnc0Uacgq-beD_4/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Карточки-схемы для фиксации результатов опыта',
    link: 'https://docs.google.com/presentation/d/1SEjNKwa66lf1150etMYMfpmk1QEiZmsu/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Конспекты',
    link: 'https://docs.google.com/presentation/d/1SEjNKwa66lf1150etMYMfpmk1QEiZmsu/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'ЭКОНОМИЧЕСКОЕ ВОСПИТАНИЕ\n' +
        'ДОШКОЛЬНИКОВ: формирование\n' +
        'предпосылок финансовой\n' +
        'грамотности',
    link: 'https://drive.google.com/file/d/1Rx4Xvj9S3CfIeyEVTEpUVBLftMvC8oH6/view?usp=drive_link'
  },
  {
    name: 'Консультация для воспитателей и родителей: пальчиковая гимнастика',
    link: 'https://docs.google.com/document/d/11rficyPio1057g9Az4nlz1yGqqD196_z/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Картотека "Космос"',
    link: 'https://drive.google.com/file/d/1hK0diYESE13fwDlB1f3pVy35-fvubgrS/view?usp=drive_link'
  },
  {
    name: 'Картотека опытов для детей старшего дошкольного возраста',
    link: 'https://drive.google.com/file/d/1WVYZ0CUf3cg_GJst6vb0X-y4CYBCZNeH/view?usp=drive_link'
  },
  {
    name: 'Тетрадь для старшей логопедической группы детского сада',
    link: 'https://docs.google.com/document/d/1avmlOCyLCZGsE2DSM9RI0ydQSnpRE6wG/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Безопасные сказки: беседы с детьми о безопасном поведении дома и на улице',
    link: 'https://docs.google.com/document/d/1avmlOCyLCZGsE2DSM9RI0ydQSnpRE6wG/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Лэпбук "Моя семья"',
    link: 'https://drive.google.com/file/d/1dFQU-ChOfDtwdHMoX2XOlHJd-oJhRtap/view?usp=drive_link'
  },
  {
    name: 'Лексическая тема "СЕМЬЯ"',
    link: 'https://drive.google.com/file/d/1nF-tKyXl5OxJXLa1K5k_uCf7JCtvrQnk/view?usp=drive_link'
  },
  {
    name: 'Проект\n' +
        '" Моя семья и семейные традиции", \n' +
        'средняя группа',
    link: 'https://drive.google.com/file/d/1Rv_VHznyrYHCwDz3xXZLrys1iq6hUGyp/view?usp=drive_link'
  },
  {
    name: 'Адаптация ребёнка к детскому саду: советы педагогам и родителям',
    link: 'https://drive.google.com/file/d/18ocaiV-NncKUvAMxUFqFInHuFtzey9hN/view?usp=drive_link'
  },
  {
    name: '200 упражнений для развития общей и мелкой моторики',
    link: 'https://drive.google.com/file/d/18ocaiV-NncKUvAMxUFqFInHuFtzey9hN/view?usp=drive_link'
  },
  {
    name: 'ИГРЫ-ЗАНЯТИЯ \n' +
        'НА ПРОГУЛКЕ\n' +
        'С МАЛЫШАМИ',
    link: 'https://docs.google.com/document/d/1AWh8Z0axB5pyOp_qnlRxGTfaxK6lEgrB/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Живая экология: программа экологического образования дошкольников',
    link: 'https://drive.google.com/file/d/1d7EeJ1Dy2CwpmDGaPCUtPwYNXjqckoDf/view?usp=drive_link'
  },
  {
    name: 'Экологическое воспитание дошкольников через подвижные игры',
    link: 'https://drive.google.com/file/d/1PuILNV4noR1gAFsoIqnh5a2pWOIpF-B1/view?usp=drive_link'
  },
  {
    name: 'Игровые технологии в образовании',
    link: 'https://drive.google.com/file/d/1gEOWZmT4sM-Zotdyt_8g_cQp-wfadqpw/view?usp=drive_link'
  },
  {
    name: 'Картотека бесед по теме «Моя семья»',
    link: 'https://drive.google.com/file/d/1lSPeKVlCp2bLZnzYNiqCNUWUx80xLN6f/view?usp=drive_link'
  },
  {
    name: 'Указ Президента Росиийской Федерации о проведении Года семьи',
    link: 'https://drive.google.com/file/d/1TNVXxY7HSt32XvHykFv6e9hFkYiEGxEz/view?usp=drive_link'
  },
  {
    name: 'Творческий вечер, посвящённый дню рождения А. С. Пушкина',
    link: 'https://drive.google.com/file/d/1Otp4wB4HnEhRs0BcB8h6pP7XMbSy2WsK/view?usp=drive_link'
  },
  {
    name: 'Проект тематической недели «Сказки А.С.Пушкина».\n' +
        'Подготовительная к школе группа.',
    link: 'https://docs.google.com/presentation/d/1NV-iEfbQtuOMtsLRz3kd2k2pVDov0HT_/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Нейропсихологические игры',
    link: 'https://drive.google.com/file/d/1OXUR0CctB3DgvH-rXX78wmYxYMMiuFPL/view?usp=drive_link'
  },
  {
    name: 'Методическая разработка\n' +
        '«Нейропсихологические игры и\n' +
        'упражнения в коррекции речевых\n' +
        'нарушений у дошкольников с ОВЗ»',
    link: 'https://drive.google.com/file/d/1a0HxvHAJiFQf_I00_4uxdTtPCA_zt5_3/view?usp=drive_link'
  },
  {
    name: 'Создание развивающей\n' +
        'образовательной среды\n' +
        'в дошкольных\n' +
        'образовательных\n' +
        'организациях\n' +
        'в соответствии\n' +
        'с ФГОС ДО',
    link: 'https://docs.google.com/document/d/1RCp9BeiQxRaa9PAF_NnCdlwUoiqjlBre/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Приветствия для хорошего настроения',
    link: 'https://drive.google.com/file/d/1E5wzGmA1p0JbocnR15OI_sw8G57bHySf/view?usp=drive_link'
  },
  {
    name: 'Конспекты занятий по духовно-нравственному воспитанию\n' +
        'дошкольников',
    link: 'https://drive.google.com/file/d/1K1zpMoZarkIoyKXFBVTrGAMIQ2D52DCh/view?usp=drive_link'
  },
  {
    name: 'Комплексные занятия для подготовительной группы',
    link: 'https://docs.google.com/document/d/1QYqU48WlR_rrz-ILQWk-38lNl3EEk94f/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Нетрадиционное рисование с дошкольниками',
    link: 'https://drive.google.com/file/d/1xVPsdJrs1j28R0GXKqTElLAC6FLpBh6y/view?usp=drive_link'
  },
  {
    name: 'Комплексные занятия по программе "От рождения до школы". Средняя группа',
    link: 'https://docs.google.com/document/d/1UNFMSDHRgS_ERC6k5G6IuMrge6240OM5/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Комплексные занятия по программе "От рождения до школы". Старшая группа',
    link: 'https://docs.google.com/document/d/1cNSUHy-8TssxDPQhU_2v6heeSfRpVPch/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Комплексные занятия по программе "От рождения до школы". Первая младшая группа',
    link: 'https://docs.google.com/document/d/1YZdNgKyTB7lV3psbSfhX01yVUrRirTTj/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Беседы об экономике. Методическое пособие',
    link: 'https://drive.google.com/file/d/1RTW4XYoLPuBAUWsCqBPrAVruIm0gUvsY/view?usp=drive_link'
  },
  {
    name: '"Деньги" - маленькая энциклопедия для школьников',
    link: 'https://drive.google.com/file/d/1Y6RAkfWznDuGecE6f3r9hOEyeuf6smh9/view?usp=drive_link'
  },
  {
    name: '"Знай и умей" - игры для детей 3-7 лет',
    link: 'https://drive.google.com/file/d/17CFO1dt-39bUkyUnVvKZe4B6-GI09yBe/view?usp=drive_link'
  },
  {
    name: 'Социально-нравственное воспитание\n' +
        'дошкольников. Методическое пособие',
    link: 'https://drive.google.com/file/d/1D6pjj2BLm8L9A64xov2pByN9czEgoGB5/view?usp=drive_link'
  },
  {
    name: 'Программа "От звука к букве. Формирование аналитическо-синтетической активности как предпосылки для обучения грамоте"',
    link: 'https://drive.google.com/file/d/1ApF45JJvngy_jFZEIWDpWi3P3cv_Ur1V/view?usp=drive_link'
  },
  {
    name: 'РАБОЧАЯ ТЕТРАДЬ\n' +
        '«В ГОСТЯХ У ЛЮБОЗНАЙКИ»',
    link: 'https://drive.google.com/file/d/1AZjpTjCnZqqOSLYGyqP0D-V0ieNrBPiz/view?usp=drive_link'
  },
  {
    name: 'РАБОЧАЯ ТЕТРАДЬ\n' +
        'БУДУЩЕГО ИЗБИРАТЕЛЯ',
    link: 'https://drive.google.com/file/d/1Ca-czckLJBOZWAUBva3iyba1uKbIlMyo/view?usp=drive_link'
  },
  {
    name: 'РАБОЧАЯ ТЕТРАДЬ\n' +
        'по формированию навыков\n' +
        'личной гигиены\n' +
        'для детей младшего возраста\n' +
        '«ВЕСЕЛАЯ ГИГИЕНА»',
    link: 'https://drive.google.com/file/d/1RGezmYRXQyIE51fIssS7dBGfRcw8C0l3/view?usp=drive_link'
  },
  {
    name: '"Безопасность" - рабочая тетрадь для старших дошкольников',
    link: 'https://drive.google.com/file/d/1HqoUG5MKDW5XLMXGqZsRNkPPajd68MDJ/view?usp=drive_link'
  },
  {
    name: '"Русские промыслы" - головоломки, лабиринты',
    link: 'https://drive.google.com/file/d/1jy9U-VS55HWL1O23RIx1ekwHtPxBOBck/view?usp=drive_linkР'
  },
  {
    name: '«Путешествие в страну\n' +
        'физкультуры и здоровья» - \n' +
        'Рабочая тетрадь',
    link: 'https://drive.google.com/file/d/1537lz5cv8CTcYXtPryx3ufBVBvvSJWyR/view?usp=drive_link'
  },
  {
    name: 'Рабочая тетрадь\n' +
        'по программе развития патриотического самосознания у старших\n' +
        'дошкольников «Мир в твоих руках»',
    link: 'https://drive.google.com/file/d/1gvFPfDPcOxSX3oxs7ZPGd4bl6o-rEjNu/view?usp=drive_link'
  },
  {
    name: 'Рабочая тетрадь с развивающими заданиями «Правила для маленьких\n' +
        'пешеходов»',
    link: 'https://drive.google.com/file/d/1uj99d1XgXmCoYz1Q9S0TSC4ofwbkB-af/view?usp=drive_link'
  },
  {
    name: 'Народы России',
    link: 'https://drive.google.com/file/d/1S88cAdP2Js71_uFh2DvSagsNo7MjZ817/view?usp=drive_link'
  },
  {
    name: 'Природа России',
    link: 'https://drive.google.com/file/d/14Q53JiwcB3hLKqH3xQYB3duKiApujuZd/view?usp=drive_link'
  },
  {
    name: 'Лексические темы по развитию речи детей дошкольного возраста',
    link: 'https://drive.google.com/file/d/146GUhMXMdxjD4m03laiHGcbYSJlZP02n/view?usp=drive_link'
  },
  {
    name: 'Лексика - "Зимующие птицы"',
    link: 'https://drive.google.com/file/d/1v7aK4ag9QQYyRnuB_PWZ3pnWAuO-s8P4/view?usp=drive_link'
  },
  {
    name: 'Грамматика в играх и картинках - перелётные и зимующие птицы',
    link: 'https://drive.google.com/file/d/1wS5hiCUMd2tEEPNl8fxHOyIZunixHvCu/view?usp=drive_link'
  },
  {
    name: '"Развиваем речь, играя", часть 1',
    link: 'https://drive.google.com/file/d/1aXDyOGUtBaEqmFkVB3nBfk_4IQ83lDZb/view?usp=drive_link'
  },
  {
    name: '"Развиваем речь, играя", часть 2',
    link: 'https://drive.google.com/file/d/1zs0s1kp91b4PvVyqEk7EPgDerWMZDD0v/view?usp=drive_link'
  },
  {
    name: '20 лексических тем',
    link: 'https://drive.google.com/file/d/1K3NUZgcXGqgMRrvWnWaw1Lc_b-n1jbGk/view?usp=drive_link'
  },
  {
    name: 'Лексические темы по развитию речи детей дошкольного возраста (старшая группа)',
    link: 'https://drive.google.com/file/d/1ggxyA231x6hqv67uLhs8Sd3Ap-CgPSt8/view?usp=drive_link'
  },
  {
    name: 'Лексические темы по развитию речи детей дошкольного возраста (средняя группа)',
    link: 'https://drive.google.com/file/d/1nrcus2lrRZVzt6qj1ExVOvKabDtAGulX/view?usp=drive_link'
  },
  {
    name: 'Организация сюжетной игры в детском саду',
    link: 'https://drive.google.com/file/d/1FHaHEqBhFyVTeUxZ1x9CL46zo4UtoNpD/view?usp=drive_link'
  },
  {
    name: '18 января - Всемирный день снеговика',
    link: 'https://docs.google.com/presentation/d/1yXTywk5TZWcfnXPBSY_5oYCjZfIGi2Tp/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true'
  },
  {
    name: 'Природные зоны для образовательной деятельности дошкольникам',
    link: 'https://drive.google.com/file/d/1-XsxIsaGRinqcUVXlIJ1gydBNdWNLgeV/view?usp=drive_link',
  },
  {
    name: 'Изображения транспорта',
    link: 'https://drive.google.com/file/d/1jeCBxTGie4Fx2pn7PrybggOFufRSWFg-/view?usp=drive_link',
  },
  {
    name: 'Картотека ритуалов окончания занятий с детьми',
    link: 'https://drive.google.com/file/d/17UiEM3HA4b7kpeQoGwNJbGsi6edtatyw/view?usp=drive_link',
  },
  {
    name: 'Дидактические игры. Транспорт',
    link: 'https://docs.google.com/document/d/1648KN_oa6v7Wx8hVA_6jwXcR8B7-ty8l/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Дидактические игры ПДД',
    link: 'https://docs.google.com/document/d/1iIUcOZf8PQATx9NMxYbJSpWgIJmeawLe/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Антонимы',
    link: 'https://drive.google.com/file/d/1NmwyVAVXMRJJLBO7eKkRF2AQ3XBallRA/view?usp=drive_link',
  },
  {
    name: 'День волонтёра',
    link: 'https://docs.google.com/document/d/1fgmv_CxzslEpDmzVmVwptD8v3pHB9o7A/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Патриотическое воспитание дошкольников по ФГОС',
    link: 'https://drive.google.com/file/d/1oJRv2oRo0GDiWBW_kK8TEpO9mm8mId_D/view?usp=drive_link',
  },
  {
    name: 'Конспект занятия по развитию речи',
    link: 'https://docs.google.com/document/d/17o3MoYtDLZ4fpllrxPsTFr-euUpc6jKB/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Сценарий для театра в детском саду',
    link: 'https://docs.google.com/document/d/1XHNr98k6X4FkpU0NJEFYAIROKt2v5TZh/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Беседа о времени и часах',
    link: 'https://drive.google.com/file/d/1932_fRdtl4X4dmD5-xdYTMVKp0F1EuLI/view?usp=drive_link',
  },
  {
    name: 'Деловая игра для родителей "Финансовая пирамида"',
    link: 'https://drive.google.com/file/d/1_AombxSaVUBsG2TiM2PUy8zsoiF7KeJL/view?usp=drive_link',
  },
  {
    name: 'Игры-эксперименты - старшая группа',
    link: 'https://docs.google.com/document/d/1rK0AadJowzf96ap5CeNuSkZ6FMLeESYX/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Исследовательская деятельность - как измерить тепло',
    link: 'https://drive.google.com/file/d/1gjqEaz2Ul0uizEEXN695jco6gMeohLAu/view?usp=drive_link',
  },
  {
    name: 'Истоки с патриотизмом - беседы с ребёнком',
    link: 'https://drive.google.com/file/d/1SM7J8SB1JPgLepkyma5jCdqIg1xtzgGw/view?usp=drive_link',
  },
  {
    name: 'Конспекты занятия для детей 6-7 лет "Что такое деньги"',
    link: 'https://drive.google.com/file/d/1sC80iOxzhN01sku-jcqvqbq4li8bUf0R/view?usp=drive_link',
  },
  {
    name: 'Занятие-беседа "Дети Ленинграда"',
    link: 'https://docs.google.com/document/d/1fASNhFMnm025zVufC4-qkDR1CyDkTbC_/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Зимние пейзажи русских художников',
    link: 'https://docs.google.com/document/d/1g78P4mfRwCq8GD0qoA4ZUzx8hFOiLYyp/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Картотека "Театрализованные игры для детей дошкольного возраста"',
    link: 'https://docs.google.com/document/d/1DbVKrU-EHNKtUfKB8CBiUCI56Pn9evee/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Буклет - Загадочный космос',
    link: 'https://docs.google.com/document/d/1NfSBX4duOg8TDJ4o5zsbh2CG-gC_djgu/edit?usp=drive_link&ouid=115118899210241820936&rtpof=true&sd=true',
  },
  {
    name: 'Занятия с детьми "Покорение космоса"',
    link: 'https://drive.google.com/file/d/1V4qjyGNXHjgz3A0U6YKz9I5Wyq3iw1_t/view?usp=drive_link'
  },

]
</script>

<style lang="scss" scoped>
.methodical-materials {
  margin-bottom: 50px;
}

.methodical-materials__link {
  color: #0f75bd;
  text-decoration: underline;
  &:active {
    color: #22409a;
  }
}
</style>