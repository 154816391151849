import {createI18n} from 'vue-i18n'

const messages = {
    ru: {
        about: 'Обо мне',
        news: 'Новости',
        for_teachers_and_parents: 'Педагогам и родителям',
        for_kids: 'Детям',
        methodical_materials: 'Методическая копилка',
        gallery: 'Фотогалерея',
        teachers_find: 'Педагогическая находка',
        feedback: 'Обратная связь',
    }
};

export default createI18n({
    locale: 'ru',
    messages,
});