<template>
  <div class="presentation-block">
    <span class="self-info">Микулова Наталья Алексеевна <br/>Персональный сайт воспитателя<br/>МБДОУ "Детский сад №1 г. Пыталово"</span>
  </div>
</template>

<style lang="scss" scoped>
.presentation-block {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.self-info {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .presentation-block {
    display: none;
  }
}
</style>