<template>
  <transition>
    <div v-if="modalIsOpen"
         class="modal-wrapper"
         @click="toggleModal"
         @wheel.prevent
         @touchmove.prevent
         @scroll.prevent
    >
      <div class="img-modal" @click.stop="">
        <img class="cross-icon"
             src="../../../assets/icons/circle-cross.svg"
             @click="toggleModal"
        >
        <img class="left-icon"
             :class="{'left-icon_disabled': disabled === 'left'}"
             src="../../../assets/icons/left-icon.png"
             @click="swapImg('left')"
        >
        <img
            class="right-icon"
            :class="{'right-icon_disabled': disabled === 'right'}"
            src="../../../assets/icons/left-icon.png"
            @click="swapImg('right')"
        >
        <img
            class="img-modal__img"
            :src="imgSrc"
            @click="swapImg('right')"
        >
      </div>
    </div>
  </transition>
</template>

<script setup>
const props = defineProps({
  imgSrc: {
    type: String,
    default: ''
  },
  modalIsOpen: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: String,
    default: '',
  }
});
const emit = defineEmits(['toggle-modal', 'swap-img']);

function toggleModal() {
  console.log(props);
  emit('toggle-modal');
}

function swapImg(direction) {
  if (props.disabled == 'left' && direction == 'left') return;
  if (props.disabled == 'right' && direction == 'right') return;
  emit('swap-img', direction);
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right: calc(100% - 100vw);
  overflow: hidden;
  background-color: rgb(0, 0, 0, 85%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}
.img-modal {
  position: relative;
  background-color: white;
  border: 5px solid white;
  border-radius: 10px;
  padding: 20px;
}

.img-modal__img {
  height: 500px;
  width: auto;
}

.cross-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.left-icon, .right-icon {
  padding: 2px 12px 2px 12px;
  height: 70px;
  width: auto;
  position: fixed;
  top: 45%;
  cursor: pointer;
  &_disabled {
    opacity: 50%;
    cursor: default;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 20%);
  }
  &_disabled:hover {
    background-color: rgb(0, 0, 0, 0%);
  }
}

.right-icon {
  right: 5%;
  rotate: 180deg;
}

.left-icon {
  left: 5%;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 992px) {
  .img-modal__img {
    height: 370px;
  }
}
@media screen and (max-width: 840px) {
  .img-modal__img {
    height: 350px;
  }
  .left-icon, .right-icon {
    height: 50px;
    top: auto;
    bottom: 15%;
  }
  .left-icon {
    left: 40%;
  }
  .right-icon {
    right: 40%;
  }
}
@media screen and (max-width: 600px) {
  .img-modal__img {
    height: 265px;
  }
  .left-icon, .right-icon {
    height: 40px;
    top: auto;
    bottom: 20%;
  }
  .left-icon {
    left: 35vw;
  }
  .right-icon {
    right: calc(100% - 65vw);
  }
}
@media screen and (max-width: 440px) {
  .img-modal__img {
    height: 200px;
  }
}
</style>