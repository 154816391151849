<template>
  <div class="feedback-form">
    <h4>Уважаемые посетители, буду рада услышать ваши пожелания!</h4>
    <form
        :action="'https://public.herotofu.com/v1/a9594ef0-d6d3-11ee-922b-3723632e53f6'"
        @submit="handleSubmit"
        method="POST"
    >
      <div class="form-row">
        <input class="form-input" type="text" placeholder="Имя" name="name" required />
      </div>

      <div class="form-row">
        <input class="form-input" type="email" placeholder="Ваша электронная почта" name="email" required />
      </div>

      <div class="form-row">
        <textarea class="form-input form-input__textarea" placeholder="Сообщение" name="message" required />
      </div>

      <div>
        <button class="feedback-button" type="submit">Отправить сообщение</button>
      </div>
    </form>
    <div v-if="submitted">
      <h2>Спасибо!</h2>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";

const submitted = ref(false)
function handleSubmit() {
  setTimeout(() => {
    submitted.value = true;
  }, 100);
}
</script>

<style lang="scss" scoped>
.feedback-form {
  margin-bottom: 60px;
}

.form-row {
  margin-bottom: 20px;
  width: 100%;
}

.form-input {
  width: 100%;
  border: 1px solid #0f75bd;
  border-radius: 5px;
  padding: 5px 10px;
}

.form-input__textarea {
  height: 60px;
}

.feedback-button {
  background-color: #0f75bd;
  border: 1px solid #0f75bd;
  border-radius: 5px;
  padding: 10px;
  color: white;
  transition: background-color 0.3s ease-out;
  &:hover {
    background-color: #22409a;
  }
}
</style>