<template>
  <header>

    <img class="header-gif" :src="iconSrc" alt="">
    <presentation-block></presentation-block>
    <hamburger-menu @toggle-menu="toggleMenu" :active="menuActive"></hamburger-menu>
    <vertical-navbar :active="menuActive" @close-navbar="closeNavbar"></vertical-navbar>
  </header>
</template>
<script setup>
import PresentationBlock from "@/components/HeaderContent/PresentationBlock.vue";
import HamburgerMenu from "@/components/HeaderContent/HamburgerMenu.vue";
import VerticalNavbar from "@/components/HeaderContent/VerticalNavbar.vue";

import {onMounted, ref, computed} from "vue";
let menuActive = ref(false);

const isFlowersStatic = ref(false);
const iconSrc = computed(() => {
  return isFlowersStatic.value ? require('@/assets/icons/flowers1_static.gif') : require('@/assets/icons/flowers1.gif');
})

function toggleMenu() {
  menuActive.value = !menuActive.value
}

function closeNavbar() {
  menuActive.value = false
}

onMounted(() => {
  setTimeout(() => {
    isFlowersStatic.value = true;
  }, 2800)
})
</script>

<style lang="scss" scoped>
header {
  width: 100vw;
  height: 72px;
  background: rgb(98,194,148);
  background: linear-gradient(180deg, rgba(98,194,148,1) 0%, rgba(0,149,153,1) 100%);
  color: white;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.header-gif {
  height: 125px;
  width: auto;
  margin-left: 5%;
}

#navbarNav {
  width: 100%;
}

.global-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(1,1,1,.77);
  z-index: 50;
}

@media only screen and (min-width: 992px) {
  #navbarNav {
    float: right;
  }
}


@media only screen and (max-width: 991px) {
  .navbar {
    z-index: 100;
  }

  #navbarNav {
    display: block;
    margin-left: 0;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 20px;
    background: rgb(248, 249, 250);
  }
}

@media screen and (max-width: 568px) {
  .header-gif {
    height: 100px;
  }
}
</style>