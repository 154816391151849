<template>
  <div class="main-view">
    <div class="container post-container">
      <h2>Новости</h2>
      <post-component
          v-for="(item, i) in posts"
          v-bind:key="i"
          :date="item.date"
          :links="item.links"
      >
        <template v-slot:text>
          <p class="pre-formated">{{item.text}}</p>
        </template>
        <template v-slot:photo-collection>
          <photo-collection
              v-if="item.photoAmount"
              :directory="item.photoDirectory"
              :amount="item.photoAmount"
              :in-post="true"
          ></photo-collection>
        </template>
      </post-component>
    </div>
  </div>
</template>
<script setup>
import PostComponent from "@/components/MainContent/PostComponent.vue";
import PhotoCollection from "@/components/MainContent/Photo/PhotoCollection.vue";

const posts= [
  {
    date: '28 апреля 2024',
    text: 'Вот и завершился областной этап Всероссийского профессионального конкурса «Воспитатель года России — 2024».\n' +
        'Этот конкурс традиционно является одной из площадок повышения профессионального мастерства педагогов дошкольных образовательных организаций, обмена опытом, апробации лучших педагогических практик.\n' +
        'В мероприятии приняли участие 20 самых ярких, талантливых и смелых представителей этой замечательной профессии.\n' +
        'В моей карьере педагога - это первый серьезный конкурс.\n' +
        'Хочется поделиться ощущениями от конкурса. Участие в конкурсе- это всегда драйв и открытия. Подготовка к конкурсным испытаниям- дело нелегкое: проба пера- «эссе», съемка видеоролика, разработка конспекта …. И вот вроде бы уже все готово. Продемонстрированы визитная карточка и педагогическая находка. Проведено занятие на "своих" детях. Откорректировано и еще раз проведено ... И еще раз откорректировано... И, да, еще раз проведено)) Хорошо, что сад большой))). А вопросов все больше . Как отреагируют дети на чужого педагога? Пойдут ли на контакт? А вдруг они не любят опыты?\n' +
        'И вот оно свершилось! Профессор Звездочкина помогает постичь тайны космоса ребятам. Строгое и компетентное жюри не сводит глаз с процесса. Всего 20 минут, а все надо успеть! Как один миг! Все, можно выдохнуть и приготовиться к ответу на вопросы.\n' +
        'Один миг – а пролетело пять дней! И вот с трепетом ждем подведения итогов конкурса! Я в десятке!\n' +
        'Это было незабываемо!\n' +
        'Такие замечательные девочки-"соперницы", все отзывчивые и доброжелательные, переживали друг за друга и поддерживали. Замечательные сотрудники сада и гимназии где нас принимали. Всё было атмосферно и комфортно.\n' +
        'И пусть у меня не призовое место, но я довольна результатом! Это был отличный опыт.',
    photoDirectory: 'posts/post45',
    photoAmount: 6,
  },
  {
    date: '12 апреля 2024',
    text: '«Космическая неделя в детском саду»\n' +
        '\n' +
        'Космос – это интересно, необычно, загадочно! А наша страна имеет особое отношение к этой теме: спутник Земли, Белка и Стрелка и первый человек покоривший космос, Герой Советского Союза -  летчик-космонавт Юрий Алексеевич Гагарин!\n' +
        'Дошкольное детство – важнейший период становления личности, и такие темы и мероприятия способствуют развитию патриотических чувств. Очень важно привить детям чувство гордости и уважения к своей стране, ее культуре, осознание личной причастности к жизни Родины.\n' +
        'Мы с ребятами не оставили без внимания это событие, так как с 8 апреля по 12 апреля в нашем детском саду проходила неделя, посвящённая Дню космонавтики. Это одна из самых любимых наших тем! Всю неделю мы беседовали с ребятами о космосе, о знаменитых космонавтах.\n' +
        'Дети узнали новые факты о Солнечной системе, в которой находится наша планета Земля, с условиями для существования жизни на планете, узнали много нового о Луне - спутнике Земли. А еще рассматривали иллюстрации с изображением загадочного мира звёзд и планет, устраивали викторины и отгадывали загадки.\n' +
        'Рисовали созвездия, конструировали ракеты и, конечно, было много опытов и экспериментов.\n' +
        'Посетили музей космонавтики, получили массу впечатлений и полезных знаний. Экскурсию бесподобно провёл Комар Г. А. Без сомнения, это одна из лучших экскурсий, которые мы с ребятами посетили.\n' +
        'Дети и родители приняли активное участие в выставке поделок, посвященной Дню космонавтики.\n' +
        'Мы и пели, и танцевали.\n' +
        'Неделя прошла очень продуктивно, весело и очень жаль, что быстро.',
    photoDirectory: 'posts/post44',
    photoAmount: 11,
  },
  {
    date: '5 апреля 2024',
    text: '7 апреля - Всемирный день здоровья.\n' +
        'Воспитание ценностного отношения к своему здоровью – одна из важнейших задач дошкольной ступени образования.\n' +
        'Тематическая неделя «Я и мое здоровье» предваряла 7 апреля - Всемирный День здоровья .\n' +
        'На этой неделе мы с моими дошколятами изучали организм человека и факторы, влияющие на здоровье людей. Проблемные ситуации, занятия, моё тело, моё здоровье, игры и беседы помогали детям постигать материал . На утреннем круге дети предложили создать Азбуку Здоровья и работа закипела. Скажу вам, что создание азбуки- это весело. Повеселились от души и узнали много новых слов, вспомнили те, что знали. Но в один из дней запас слов кончился. Что же делать?! Дети пришли к выводу, что пришла пора обратиться за помощью к родителям! Сказано - сделано! И родители включились в работу, сначало робко, а потом с азартом.\n' +
        'В День Здоровья дети поучаствовали в спортивно-развлекательные мероприятиях. Получить заряд бодрости и энергии ребятам помогла весёлая зарядка «Ну-ка, все вместе».\n' +
        'Прошли соревнования «В здоровом теле - здоровый дух!»\n' +
        'Ребята активно принимали участие в эстафетах, играх, отгадывали загадки.\n' +
        'День здоровья получился дружелюбным, радостным, поднял всем детям и взрослым настроение.\n' +
        'А как известно, хорошее настроение - залог здоровья! Будьте здоровы!',
    photoDirectory: 'posts/post43',
    photoAmount: 9,
  },
  {
    date: '1 апреля 2024',
    text: 'А у нас День смеха!\n' +
        'В сад сегодня принимали только с улыбкой - Акция "Без улыбки вход воспрещён" А дальше нас ожидали веселые игры с Домовенком детского сада Кузей и Бабой Ягой: то они превращались в забавных пингвинов, то в черепашек с тазом на спине вместо панциря, то вся обувь перепутается. Веселились сами и помогали веселить друзей, в ход шло все: и танцевальные импровизации, и кричалки, загадки–обманки . Заряд хорошего настроения получен! А на прогулке ждал новый сюрприз: товарищеский футбольный матч, да не простой, а первоапрельский! Попробуй вместо мяча шар забить в ворота!\n' +
        'Ярко и весело прошёл наш День смеха.',
    photoDirectory: 'posts/post42',
    photoAmount: 9,
  },
  {
    date: '29 матра 2024',
    text: 'Вот и подошла к концу наша неделя каникул "Неделя театра и книги". Сколько всего у нас произошло! Устроили выставку книг Н. Н. Носова и викторину по произведением автора. И мамы наши нам читали, Дашина мама нам прислала видеозапись чтения рассказов Носова, а затем Анина мама приходила, мы слушали живое чтение. После чтений мы устроили инсценировку по рассказам Н. Носова, и Мария Александровна с радостью поучаствовала в наших сценках. Вечер мы завершили изготовлением афиши к Дню театра.\n' +
        '27 марта – Всемирный День Театра.\n' +
        'В этот день ребят ждал театральный праздник с яркий названием «Театр- это чудо»! Мы погрузились в мир театра: от кассы и оркестровой ямы до его различных видов и мастер-классов по актерскому мастерству. Кстати, у входа в зал ребят ждали билетеры, а ценой билета была - Улыбка! К этому дню мы подготовили драматический спектакль по рассказу Драгунского «Если б я …» Ребята из других групп показали кукольный спектакль и мюзикл. И в финале мои девочки выступили с фрагментом из балета "Лебединое озеро" "Танец маленьких лебедей", чем вызвали настоящие овации!\n' +
        'В четверг мы устроили представление для малышей. Сделали для них настоящий праздник, показали им нашу постановку рассказа, поиграли и конечно показали наш танец "Маленьких лебедей". Дети к этому дню сделали маски своими руками и в финале праздника подарили их малышам. Праздник мы закончили балом масок. А потом до конца дня в группе мы развлекались с настольным театром, устроили маски-шоу. Вспоминали известные нам сказки и сочиняли сказки на новый лад, и это было очень весело! Дети- самые лучшие и искренние артисты!\n' +
        'И в последний день недели у нас в саду состоялся клубный час. Чего тут только не было! И нужны были помощники, чтобы помочь муравьишке вернуться домой, и мастерская открылась по изготовлению веницианской маски, мастерская "Книжкина закладка", и много ещё чего. День пролетел незаметно. Эмоции зашкаливали.\n' +
        'Наша творческая неделя пролетела как один день. ',
    photoDirectory: 'posts/post41',
    photoAmount: 12,
  },
  {
    date: '18 марта 2024',
    text: 'Сегодня 18 марта - День воссоединения Крыма с Россией\n' +
        'У нас в детском саду прошел тематический день, посвященный 10-летию воссоединения Крыма с Россией.\n' +
        'С утра моих детей ждал мастер-класс «Крымский пейзаж» с педагогом Детской школы искусств Снеговой А.Н., ребята под ее руководством начали работу над проектом.\n' +
        'Потом мы с детьми сделали тематический обзор «Крым - частица России», познакомились с Крымской республикой и её достопримечательностями.\n' +
        'Воспитатель Иванова Е. П. провела интерактивно-познавательную программу для старших дошкольников «Крымская весна» . Праздник получился ярким, спортивным, позитивным, включил в себя детскую видеопрезентацию Крыма, флешмоб, конкурсы, загадки, татарскую игру "Тюбитейка".\n' +
        'На этом знакомство с Крымом у нас с ребятами не закончилось: дети раскрашивали раскраски про Крым, прогулялись по Крыму благодаря настолькой игре-ходилке. Посмотрели видео о том как строился Крымский мост, а в итоге его нарисовали. День прошёл насыщенно и плодотворно!',
    photoDirectory: 'posts/post40',
    photoAmount: 7,
  },
  {
    date: '15 марта 2024',
    text: '15 марта - Международный день добрых дел!\n' +
        'В каждом из нас живет частица добра и милосердия, сострадания и искреннего желания совершать добрые поступки. Когда мы делаем что-то хорошее для другого человека, нам самим становится лучше и тепло на душе.\n' +
        'И наш отряд "Добрые ладошки" к этому дню решили собрать вкусняшки и сделать открытки для одиноких дедушек и бабушек. Всё это мы с ребятами отнесли на социальную станцию. Приятно делать добрые дела!',
    photoDirectory: 'posts/post39',
    photoAmount: 5
  },
  {
    date: '13 марта 2024',
    text: 'Масленица к нам пришла,\n' +
        'Радость людям принесла,\n' +
        'Ну а мы придем с блинами,\n' +
        'Встретить праздник вместе с вами!\n' +
        'У нас в детском саду 13 марта состоялось детское гулянье-развлечение «Гуляй, Масленица!»\n' +
        'Масленица - это встреча Весны и проводы Зимы, озорной и веселый праздник.\n' +
        'Это развлечение у нас ежегодно проводится на улице и стало уже традиционным и любимым для детей.\n' +
        'Вот и в этом году мы с детьми стали участниками проводов Масленицы.\n' +
        '🥳Скоморох с Хозяюшкой встретили ребятишек и рассказали детям про обычаи и правила, которые связаны с Масленичной неделей. Они узнали, как раньше на Руси праздновали Масленицу, что означает каждый день масленичной недели. Не обошлось и без проделок Бабы Яги, которая всё время пыталась помешать проведению праздника. Но дружба помогла ребятам с лёгкостью справиться с её заданиями: они соревновались в беге с метёлками, отгадывали загадки, перетягивали канат, пекли блины и танцевали в хороводе.\n' +
        'Весна несет в себе жизнь, пробуждение природы, солнечный свет и тепло.\n' +
        '🌞Именно в честь Солнца в этот праздник пекут блины🌕, ведь они круглые, желтые и теплые, как солнце. Угощением детей вкусными и ароматными блинами, которые с любовью приготовили наши повара, закончился праздник.\n' +
        '🌕«АХ, блины, блины, блины, вы блиночки мои!»',
    photoDirectory: 'posts/post38',
    photoAmount: 6
  },
  {
    date: '7 марта 2024',
    text: 'Нежный, трогательный, замечательный праздник 8 Марта в этом году мы отмечаем под эгидой Года Семьи в России.\n' +
        'И поэтому мы выбрали новую форму проведения утренника - музыкальное семейное кафе. Наше кафе называлось "Мамина улыбка".\n' +
        'Заказы блюд поступали от мам и бабушек очень необычные: то салатик "Морской " закажут, то "Энергетический коктейль".\n' +
        'А мои дети рады стараться - подготовили как раз такие музыкальные номера концерта. А как трогательно было смотреть, когда после исполнения номеров детки бежали за столики к своим мамам, а те обнимали и целовали их за старания!\n' +
        'Кафе семейное, и выступали не только дети: мамы и бабушки с детьми разыгрывали сценки из семейной жизни, читали стихи признания в любви к своим дочкам-сыночкам, так что Фрекен Бок (герой нашего утренника) прослезились не раз от умиления и нежности момента.\n' +
        'По традиции дети подарили мамам рукотворные подарочки, девочки получили сюрпризики от мальчиков, и все порадовались сладким плюшкам для чаяпития в группе.',
    photoDirectory: 'posts/post37',
    photoAmount: 5
  },
  {
    date: '1 марта 2024',
    text: '1 Марта – это не только первый день весны, но и замечательный праздник – Всемирный день кошек.\n' +
        'В России 1 марта отмечается Международный день кошек.\n' +
        '\n' +
        'Мы с детьми с удовольствием отметили этот праздник: познакомились с праздником, побеседовали о домашних животных кошках. Они дарят море позитивных эмоций людям. А люди должны заботиться о них, кормить, ухаживать.\n' +
        '\n' +
        'Дети узнали о том, что помимо «эстетического удовольствия» кошки выполняют ряд полезных для человека функций: ни отлично истребляют грызунов, лечат некоторые заболевания и даже продлевают жизнь своему хозяину.\n' +
        '\n' +
        'Ребята познакомились с тем, что в мире существует множество поверий и примет, связанных с кошками. Самые распространенные – что кошки проживают несколько жизней, а еще могут интуитивно определять намерение пришедших в дом людей и чувствуют недобрых гостей. Познакомились дошкольники и с пословицами о кошках: «тянуть кота за хвост», «страшнее кошки зверя нет», «доброе слово и кошке приятно», «кошка в дом – радость в нем», «кошке игрушки, а мышке слезки», «ночью все кошки серы».\n' +
        '\n' +
        'Дети прошли лабиринты с кошками; почитали стихи и сказки о кошках. Вспомнили в каких мультфильмах есть кошки. \n' +
        '\n' +
        'В группе была организована выставка книг посвященная Всемирному дню кошек.\n' +
        '\n' +
        'Вот как интересно прошел день с кошками. Такие мероприятия помогают воспитывать у детей доброе и заботливое отношение к домашним животным.',
    photoDirectory: 'posts/post34',
    photoAmount: 6,
  },
  {
    date: '29 февраля 2024',
    text: 'Дети и родители приняли участие в выставке макетов военной техники, приуроченной к празднику «День защитника Отечества»\n' +
        '\n' +
        'Любая техника, а особенно военная, всегда привлекает внимание ребят. Она вызывает у них неподдельный интерес и пробуждает желание смастерить какую-нибудь не очень сложную модель своими руками.\n' +
        '\n' +
        'Ребята и родители моей группы приняли активное участие в этой выставке и предоставили свои работы.\n' +
        'Поделки военной техники своими руками сделаны как из традиционных, так и из необычных материалов.\n' +
        '\n' +
        'В творческом процессе были использованы: бумага и картон, различный бросовый материал (лотки упаковок для яиц, пластмассовые крышечки, спичечные коробки, пластилин), а также воображение🙇‍♂, мастерство, терпение – все это помогло смастерить интереснейшие модели!',
    photoDirectory: 'posts/post33',
    photoAmount: 2,
  },
  {
    date: '21 февраля 2024',
    text: '21 февраля - Международный день родного языка✍\n' +
        '\n' +
        'Наша страна заключает в себе разнообразие народов, проживающих на её территории, и может похвастаться количеством языков, на которых они говорят.\n' +
        '\n' +
        '193 народа, проживающих в России, используют приблизительно 277 языков (по другим данным – 295) и диалектов. Такая статистика приводится в Стратегии государственной национальной политики Российской Федерации на период до 2025 года и официальных данных Института языкознания РАН соответственно. Язык этноса помогает сохранять его историю и культуру, традиции и бытовой уклад. И сегодня ученые, педагоги и международные общественные организации ставят задачу — сберечь языковое богатство мира.\n' +
        '(Источник: https://clck.ru/38wdJp)\n' +
        '\n' +
        'Международный день родного языка был учрежден в 1999 году решением 30-й сессии Генеральной конференцией ЮНЕСКО.\n' +
        'Ежегодно праздник отмечается 21 февраля. Цель праздника – содействие языковому и культурному разнообразию и многоязычию, сохранению исчезающих языков, культур и народностей.',
    photoDirectory: 'posts/post32',
    photoAmount: 9,
  },
  {
    date: '19 февраля 2024',
    text: 'Как известно, "у природы нет плохой погоды":\n' +
        'дождь, снег, мороз , дождь, мороз, и вот новая зимняя лаборатория открыта!\n' +
        'Вышли мы с детьми сегодня погулять и с первыми шагами поняли - сегодня что то не совсем обычное!❄❄❄\n' +
        'Снег сегодня с коркой. Так познакомились с новым зимним понятием - наст.\n' +
        'И тут посыпались идеи:🗣\n' +
        '-А давайте соберем выставку ледяных корок.\n' +
        '-А давайте у кого больше в размере.\n' +
        'Прогулка перешла в целенаправленно изучение наста: толщина, свойства, способ применения..... ассоциации - на что похоже.Занятие себе нашли все)',
    photoDirectory: 'posts/post35',
    photoAmount: 4,
  },
  {
    date: '15 февраля 2024',
    text: 'Число информационных атак при помощи поддельных аккаунтов в Telegram представителей власти или руководителей организаций значительно возросло.\n' +
        '\n' +
        'Схема мошенников практически всегда одинаковая:\n' +
        'Создается фейковый аккаунт в мессенджере.\n' +
        'Рассылаются сообщения с предупреждением, что в ближайшее время поступит звонок от «сотрудника» службы безопасности.\n' +
        'Таким образом мошенники хотят получить служебную информацию. Например, пароли от служебной почты, государственных информационных систем и другие.\n' +
        '\n' +
        '⚠Будьте внимательны!\n' +
        '\n' +
        'Если вам поступило подобное сообщение – свяжитесь с человеком традиционным способом и заблокируйте фейковый аккаунт нажав кнопку «пожаловаться».',
    photoDirectory: 'posts/post30',
    photoAmount: 1,
  },
  {
    date: '10 февраля 2024',
    text: '10 февраля - День памяти Александра Сергеевича Пушкина.\n' +
        '\n' +
        'Ежегодно 10 февраля отмечается День памяти Александра Сергеевича Пушкина – русского поэта, прозаика, драматурга, литературного критика, основоположника русского литературного слова.\n' +
        '\n' +
        'За свою недолгую жизнь А.С. Пушкин создал огромное количество литературных произведений, ставших классикой русской литературы.\n' +
        '\n' +
        'В 2024 году исполняется 225 лет со Дня рождения великого русского поэта.',
    photoDirectory: '',
    photoAmount: 0,
  },
  {
    date: '7 февраля 2024',
    text: 'Девочка на шаре🎈\n' +
        'В новой программе ФОП ДО много внимания уделяется знакомству с искусством великих художников🎨 , и это нелегко дается и педагогам и детям, ведь нет у нас доступа к большим музеям . И вот еще один интересный случай из жизни нашей группы: утром познакомились с картиной П. Пикассо "Девочка на шаре" . Вышли на прогулку, ребята увидели оставшиеся от крепости снежные комы❄, и тут же появилась идея повторить позу девочки.... Ожившая картина!\n' +
        'Это было невероятно круто - мальчики аплодировали каждой гимнастке, помогали забраться. Думаю, этот случай будет иметь продолжение....',
    photoDirectory: 'posts/post36',
    photoAmount: 4,
  },
  {
    date: '30 января 2024',
    text: 'Наш волонтёрский отряд «Добрые ладошки» подготовительной группы № 7 побывал в Ресурсном центре.\n' +
        'Мы пришли помочь координаторам народного движения «Пыталово своих не бросает» упаковать коробки с гуманитарной помощью и клеить на них опознавательные наклейки.\n' +
        'Прежде чем приступить к важному и ответственному заданию, специалист Молодёжного центра Алла Бирюлева рассказала юным волонтёрам, чем занимаются в ресурсном центре, как проводится сбор помощи бойцам СВО, что приносят жители для отправки. Специалист Наталья Медведева раздала ребятам наклейки и проинструктировала, как их клеить. Следил за процессом и помогал юным помощникам активист народного движения Дмитрий Ершов.',
    photoDirectory: 'posts/post10',
    photoAmount: 4,
  },
  {
    date: '30 января 2024',
    text: 'И снова о прорыве блокады. Мы с подготовительной группой №7 приняли участие во Всероссийских конкурсах "Блокада Ленинграда" и "Ленинградская Победа", получив за свои творческие работы Дипломы за 1 место! Блокадная ласточка – это маленький жестяной значок, на котором изображена ласточка с письмом в клюве. Весной 1942 года его начали носить на одежде многие жители Ленинграда в ответ на заявление немецкого командования: «Отныне даже птица не сможет пролететь через кольцо блокады».',
    photoDirectory: 'posts/post9',
    photoAmount: 3,
  },
  {
    date: '27 января 2024',
    text: 'Вчера я предложила детям выглянуть на улицу из окна, но не из группового, а вот из этого Ленинградского окошка 1943 года.\n' +
        'Что там?- боль, голод, страдание и Надежда. Таким погружением в военное время начала я разговор с детьми о предстоящей важной и значимой для нашей страны даты - 27 января, дня полного освобождения Ленинграда от фашистской блокады.\n' +
        'Ребята узнали, что главным символом прорыва Блокады является Мемориал «Разорванное кольцо» на берегу Ладожского озера. Дети нарисовали мемориал и передали в своих работах важное и трогательное послание о несгибаемости и силе духа нашего народа.\n' +
        'Пусть эти рисунки станут напоминанием о важных событиях нашей истории, о которых мы должны помнить и передавать память следующим поколениям. \n' +
        'Ребята так же познакомились с символами Блокадная ласточка и Ленинградская ленточка, которая повторяет цвета и является частью медали «За оборону Ленинграда» - главной награды блокадников.',
    photoDirectory: 'posts/post8',
    photoAmount: 9,
  },
  {
    date: '25 января 2024',
    text: 'ГБОУ ДПО «Псковский областной институт повышения квалификации работников образования» проводит региональное родительское собрание\n' +
        '«В семье растёт будущий первоклассник».\n' +
        'Повестка дня собрания:\n' +
        '1. Как помочь ребёнку стать школьником\n' +
        '2.Психологическая готовность ребёнка к школе\n' +
        '3. Правила приёма в школу в 2024 году\n' +
        'Спикеры:\n' +
        'доцент кафедры дошкольного и начального образования ПОИПКРО Надежда Чернушевич;\n' +
        'заведующий центром практической психологии, специального образования и охраны здоровья Надежда Алексеева;\n' +
        'специалист Управления образования Администрации города Пскова Татьяна Егорова.\n' +
        '\n' +
        'Собрание состоится 30 января 2024 года в 16.00\n' +
        'Трансляция собрания по ссылке: https://clck.ru/38AfNj\n' +
        'Логин ФИО пароль 19506972',
    photoDirectory: 'posts/post27',
    photoAmount: 2,
  },
  {
    date: '24 января 2024',
    text: '" Школа юных модельеров " открылась в нашей подготовительной группе №7 на прошлой неделе! Играя в сюжетную игру "Ателье" маленькие модники и модницы стали настоящими дизайнерами, портными и закройщиками , и конечно - моделями! А Вам слабо, вот так запросто и мастерски снять мерки и сделать ровные швы?!! Вот где пригодились знания и умения, данные мне на занятиях математики и аппликации.А сколько эмоций!!',
    photoDirectory: 'posts/post7',
    photoAmount: 1,
  },
  {
    date: '19 января 2024',
    text: 'День рождения Снеговика в подготовительной группе №7\n' +
        'Ребята узнали, почему день снеговика отмечают 18 января, когда появился первый снеговик, читали стихи и загадки. Нарисовали снеговиков и сделали коллективную работу. А еще ребята приняли участие во Всероссийском творческом онлайн-флешмобе "Весёлый снеговик" и получили Сертификат участника .',
    photoDirectory: 'posts/post6',
    photoAmount: 10,
  },
  {
    date: '18 января 2024',
    text: 'Дата празднования Дня снеговика — 18 января — была выбрана из следующих соображений: во-первых, день не затеряется в череде зимних праздников, а во-вторых, сочетание цифр 1 и 8 похоже на снеговика с метлой.',
    photoDirectory: 'posts/post26',
    photoAmount: 10,
  },
  {
    date: '10 января 2024',
    text: 'Президент Владимир Владимирович Путин объявил 2024 год Годом семьи!\n' +
        'Правительство по поручению главы государства утвердило план основных мероприятий по его проведению.\n' +
        '\n' +
        'В рамках данной работы планируется:\n' +
        '\n' +
        'разработать стратегический документ в части демографической и семейной политики на перспективу до 2030 года,\n' +
        'закрепить на федеральном уровне единый правовой статус и основные социальные гарантии для многодетных,\n' +
        'по поручению Президента продлить действие денежной выплаты при рождении третьего ребенка, которую можно направить на погашение ипотеки.\n' +
        '\n' +
        'Особое внимание будет уделено вопросам охраны здоровья граждан, включая тех, кто собирается стать родителями.\n' +
        '\n' +
        '«Поддержка граждан, которые воспитывают детей, помощь родителям, забота о подрастающем поколении – всегда были и остаются приоритетами для правительства», – подчеркнул Михаил Мишустин.\n' +
        '\n' +
        'Татьяна Голикова рассказала о мероприятиях в рамках Года семьи.\n' +
        '\n' +
        '«Наша общая задача – возродить уважительное отношение к большой семье, способствовать укреплению семейных ценностей», – сказала вице-премьер.\n' +
        '\n' +
        'Ключевыми задачами станут создание условий для рождения первых детей в молодых семьях, укрепление репродуктивного здоровья, поддержка многодетных и многопоколенных семей, формирование здоровой моды на многодетную семью.\n' +
        '\n' +
        'Федеральный план состоит из нескольких блоков:\n' +
        '\n' +
        'Первый блок включает:\n' +
        '•чествование родителей, награжденных знаком «Мать-героиня», медалью и орденом «Родительская слава»,\n' +
        '•масштабные всероссийские конкурсы «Это у нас семейное», «Семья года»,\n' +
        '•торжественные мероприятия ко Дню семьи, любви и верности и ряд других мероприятий.\n' +
        '\n' +
        'Первый урок в День знаний и школьные выпускные посвятят тематике Года семьи.\n' +
        '\n' +
        'Открыть Год семьи предлагается всероссийским семейным форумом «Родные – любимые», он пройдет на выставке «Россия» на ВДНХ с 19 января.\n' +
        '\n' +
        'Второй блок касается мероприятий по совершенствованию положения семей с детьми:\n' +
        '•разработки стратегического документа в части демографической и семейной политики на период до 2030 года,\n' +
        '•работы, связанной с льготным ипотечным кредитованием и продлением в I квартале выплат в размере 450 тыс. рублей семьям с тремя и более детьми,\n' +
        '•утверждения единого правового статуса многодетной семьи,\n' +
        '•выявления и продвижения совместно с работодателями лучших корпоративных практик поддержки семей.\n' +
        '\n' +
        'Третий блок предполагает проведение культурно-массовых, спортивных, общественных мероприятий.\n' +
        '\n' +
        'Два раздела плана направлены на работу с родителями, в том числе будущими.\n' +
        '\n' +
        'Председатель Правительства поручил вице-премьеру держать реализацию плана на личном контроле.\n' +
        '\n' +
        '«В нашей стране семья – это главная ценность. И очень важно сделать все для того, чтобы рождалось как можно больше детей, чтобы родители получали максимальную поддержку и помощь», – подчеркнул Михаил Мишустин.',
    photoDirectory: 'posts/post25',
    photoAmount: 8,
  },
  {
    date: '20 декабря 2023',
    text: 'Правила пожарной безопасности в Новогодние и Рождественские праздники!',
    photoDirectory: 'posts/post23',
    photoAmount: 9,
  },
  {
    date: '15 декабря 2023',
    text: 'Начинаются Новогодние чудеса!\n' +
        'На этой неделе ребятам подготовительной №7 и старшей группы пришло письмо из В. Устюга от самого Деда Мороза!. Вот уж тут глазки загорелись: и любопытно, и гордость берет, и даже немного боязно: " Что там?"',
    photoDirectory: 'posts/post5',
    photoAmount: 4,
  },
  {
    date: '7 декабря 2023',
    text: 'Предвкушение Нового Года. Каждый ждёт тот волшебный момент, когда за окном наступит зима, холод, темнота и окончание года, а в душе тепло, светло, уютно и ожидание чуда. И чтобы было понятно, когда же наступит этот момент у нас в группе появился Календарь-обратного отсчёта! Ребята получают от Деда Мороза задания на каждый день, вот сегодня, например нужно посчитать всех птиц на улице. А как вы готовитесь к встрече Нового года?',
    photoDirectory: 'posts/post4',
    photoAmount: 3,
  },
  {
    date: '4 декабря 2023',
    text: 'Катание на ватрушках зимой может быть опасным, особенно при неправильном использовании.\n' +
        '\n' +
        'Ватрушка - это надувной круг, который используется для катания с горок или склонов. Однако, при неправильном использовании или катании на небезопасных местах, есть риск получения травм.\n' +
        '\n' +
        'Вот несколько потенциальных опасностей катания на ватрушках зимой:\n' +
        '\n' +
        'Падение и столкновение: При катании на ватрушке можно потерять управление и упасть, что может привести к ушибам, вывихам или даже переломам. Также возможны столкновения с другими катальщиками.\n' +
        '\n' +
        'Небезопасные склоны: если кататься на ватрушках на склонах с опасным рельефом или узкими проходами, это также может повысить риск получения травм.\n' +
        '\n' +
        'Мороз и обморожение: длительное катание на ватрушках в холодную погоду может привести к обморожению или переохлаждению.\n' +
        '\n' +
        'Для безопасного катания на ватрушках следует выбирать безопасные места, контролировать скорость, использовать защитное снаряжение.',
    photoDirectory: 'posts/post21',
    photoAmount: 1,
  },
  {
    date: '1 декабря 2023',
    text: 'Дошкольники могут написать письмо Деду Морозу с 1 декабря\n' +
        '\n' +
        'Юным жителям Псковской области необходимо указать на конверте адрес: 162390, Россия, Вологодская область, город Великий Устюг, Дом Деда Мороза. Кроме того, важно указать и свой адрес с индексом, чтобы получить ответ от главного новогоднего волшебника.\n' +
        '\n' +
        'Каждое послание будет доставлено «Почтой», даже если отправитель опустил его в обычный почтовый ящик или сделал ошибку в адресе. В письме главное указать адресата – Деда Мороза.',
    photoDirectory: 'posts/post20',
    photoAmount: 1,
  },
  {
    date: '29 ноября 2023',
    text: 'Приближается Новый год! Все ждут подарков,даже взрослые отважные бойцы СВО. Ребята отряда "Добрые ладошки " со своими друзьями из других групп детского сада смастерили яркие открытки, а их родители передали сладкие печеньки. Все передали организаторам поездки к бойцам . Ребята с нетерпением ждут встречи и обратной связи от героев.\n',
    photoDirectory: 'posts/post19',
    photoAmount: 2,
  },
  {
    date: '16 ноября 2023',
    text: '18 ноября по всей России отмечается День рождения Деда Мороза, самого доброго и самого любимого новогоднего волшебника.\n' +
        'Вот и у нас сегодня не обошлось в этот день без волшебства.\n' +
        'Снегурочка, Ёжинька и Мышка создали атмосферу праздника для детей. После театрализованного представления на сцене и показа мультфильма, мальчишки и девчонки приняли участие в интересных играх и эстафетах. А видеопоздравление от Дедушки Мороза, доставило детям много удовольствия и восторга. Праздник в Пыталово отметили весело и ярко.\n',
    photoDirectory: 'posts/post29',
    photoAmount: 7,
  },
  {
    date: '9 ноября 2023',
    text: 'Знакомство с музеем лучше начинать с раннего детства. Первое посещение обычно очень запоминается маленьким экскурсантам.\n' +
        '\n' +
        'Псковский музей-заповедник предлагает приобрести абонемент «Добро пожаловать в музей», который подходит для дошкольников, впервые посещающих культурное учреждение.\n' +
        '\n' +
        'Абонемент «Музейный кораблик» также походит для воспитанников детских садов. В него входят культурно-образовательные программы на основе народной культуры. Занятия проводятся в детских садах в подготовительных группах.\n' +
        '\n' +
        'Записаться на абонемент и получить дополнительную информацию можно по телефонам +78112292260 и +79113580816, а также на сайте музей-заповедника.\n',
    photoDirectory: 'posts/post18',
    photoAmount: 1,
  },
  {
    date: '7 ноября 2023',
    text: 'Наши ребята подготовительной группы №7 поучаствовали во Всероссийском конкурсе "Родина моя", посвященном Дню народного единства. И вот достойные результаты. Молодцы, ребята!',
    photoDirectory: 'posts/post3',
    photoAmount: 4,
  },
  {
    date: '4 ноября 2023',
    text: 'День народного единства сегодня отмечается во всех уголках нашей страны!\n' +
        '\n' +
        'В этот день мы чтим память о событиях 1612 года. Москва была освобождена от польских интервентов, и это положило конец периоду, вошедшему в историю как Смутное время.\n' +
        '\n' +
        'В честь праздника предлагаем вспомнить детали судьбоносного периода в истории нашей страны.\n',
    photoDirectory: 'posts/post17',
    photoAmount: 8,
  },
  {
    date: '4 ноября 2023',
    text: 'Россия всех объединяет,\n' +
        'Народов много в ней живет.\n' +
        'С единством вас мы поздравляем,\n' +
        'Что празднует родной народ!\n' +
        '\n' +
        'Желаем радостью делиться\n' +
        'И мудростью на благо всех.\n' +
        'Пусть счастье с каждым приключится,\n' +
        'И ждет удача и успех!\n' +
        '\n' +
        'Единство — это мощь и сила,\n' +
        'А это много для страны.\n' +
        'Так пусть же все будут едины,\n' +
        'Все счастливы и все равны!',
    photoDirectory: 'posts/post16',
    photoAmount: 2,
  },
  {
    date: '3 ноября 2023',
    text: '"Бумбатл" - это вовсе не шум "Бум-бум" - это Всеросийскся акция по сбору макулатуры, к которой присоединилась наша группа! И наша группа собрала больше всех бумажного сырья! А собрали мы - 69,500кг. Мы -молодцы!!!',
    photoDirectory: 'posts/post2',
    photoAmount: 4,
  },
  {
    date: '1 ноября 2023',
    text: '',
    photoDirectory: 'posts/post15',
    photoAmount: 1,
  },
  {
    date: '1 ноября 2023',
    text: 'Дошкольников приглашают присоединиться к акции «Письмо солдату»\n' +
        '\n' +
        'Псковское региональное отделение Союза писателей России предлагает детям и их родителям отправить послания участникам СВО. Свыше 150 писем от юных жителей Псковской области уже получено нашими бойцами.\n' +
        '\n' +
        'В этот раз все письма 8 декабря передадут поэту и волонтёру Ирине Кучеровой в Москве на презентации книги «Позывной — Россия!», после чего она доставит детские послания бойцам на передовую.\n' +
        '\n' +
        'Дополнительную информацию ищите в группе проекта «Позывной — Россия!» в соцсети «ВКонтакте» – https://vk.com/pozyvnoj_rossiya',
    photoDirectory: 'posts/post14',
    photoAmount: 2,
  },
  {
    date: '13 октября 2023',
    text: 'В нашей жизни очень много праздников, однако есть и совершенно особые, такие, от которых веет необычайной теплотой и нежностью. Именно к таким праздникам относится и День отца, который отмечается в третье воскресенье октября. Быть хорошим отцом — самая ответственная и нужная работа в жизни мужчины. Стать примером для сына и добрым волшебником для дочери. Научить, рассказать, защитить, объяснить и сделать всё это с любовью и терпением — такое может только папа.\n' +
        'В преддверии Дня отца в нашем саду прошел тематический "Папин день", проведены «Папины мастерские ", тематические беседы, дети рассказывали о своих папах, о семейных традициях, изготавливали фотоколлажи и поздравительные открытки.\n' +
        'В гости к нам в группу пришел Карцев Павел Николаевич, папа Ксюши: рассказал про свою работу и хобби. Вместе с детьми сделали кораблики из бумаги. Также приходил Образцов Виталий Александрович, папа Саши: рассказал про свою работу (шофер-дальнобойщик), показал на карте куда он возил грузы. Поговорил с ребятам про правила дорожного движения. Рассказал про своё хобби - мастерить что-нибудь, что построил своими руками второй этих в доме. Принёс с собой немного инструментов: мы с ребятами их рассмотрели, опробывали и все вместе подкрутили стулья в группе.\n' +
        'Поздравляем с особенным праздником — Днем отца! Быть отцом это и сложно, и радостно, но в любом случае, это — счастье! Берегите и цените свой статус. Желаем много сил, терпения для исполнения такой почетной роли!',
    photoDirectory: 'posts/post1',
    photoAmount: 10,
  },
  {
    date: '12 октября 2023',
    text: 'Дошкольники могут поучаствовать в экологической акции «БумБатл»\n' +
        '\n' +
        'Юные жители Псковской области вместе со своими родителями могут внести ценный вклад в формирование экологической культуры в регионе.\n' +
        '\n' +
        '📍Акция проводится с 10 октября по 15 ноября в рамках нацпроекта «Экология».\n' +
        '\n' +
        'Ребята могут сделать несколько простых шагов, которые помогут нашей планете стать чище.',
    photoDirectory: 'posts/post13',
    photoAmount: 1,
  },
]
</script>

<style lang="scss">
.pre-formated {
  white-space: pre-wrap;
}

@media screen and (max-width: 568px) {
  .post-container {
    width: 70vw;
    margin-left: 5%;
    margin-right: 0;
  }
}
</style>