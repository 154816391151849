<template>
  <transition>
    <div
        class="vertical-navbar__wrapper"
        v-show="active"
        @click="closeNavbar"
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
    >
      <div class="vertical-navbar">
        <router-link
            @click="closeNavbar"
            @click.stop=""
            class="navigation-item"
            v-for="(item, i) in list"
            v-bind:key="i"
            :to="item == 'about' ? '/' : '/' + item"
        >{{$t(item)}}</router-link>
      </div>
    </div>
  </transition>
</template>

<script setup>
import {defineProps, onMounted, reactive} from 'vue';

const props = defineProps({active: Boolean})
const emit = defineEmits(['close-navbar'])
const list = reactive(['about', 'news', 'for_teachers_and_parents', 'for_kids', 'methodical_materials', 'gallery', 'feedback']);

onMounted(() => {
  console.log(props);
})
function closeNavbar() {
  emit("close-navbar")
}
</script>

<style lang="scss" scoped>
.vertical-navbar__wrapper {
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 85%);
  overflow: hidden;
  z-index: 5;
}
.vertical-navbar {
  display: flex;
  flex-direction: column;
}
.navigation-item {
  box-sizing: border-box;
  width: 100vw;
  color: white;
  background-color: #0f75bd;
  padding: 15px 0 15px 15px;
  border-bottom:  1px solid #22409a;
  font-size: 20px;
  z-index: 10;
  &:hover {
    background-color: #22409a;
  }
}

.router-link-exact-active {
  background: rgb(193,246,237);
  background: linear-gradient(180deg, rgba(193,246,237,1) 0%, rgba(24,194,186,1) 60%);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>